import React from 'react';
import { Grid, Row, Col, css, styled, thd, up } from '@smooth-ui/core-sc';

const currentMonth = () => {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const d = new Date();
  return month[d.getMonth()];
};

const folder = () => {
  switch (currentMonth()) {
    case 'April':
    case 'May':
      return 'April';
    case 'June':
    case 'July':
      return 'June';
    case 'August':
    case 'September':
      return 'August';
    case 'October':
    case 'November':
      return 'October';
    case 'December':
    case 'January':
      return 'December';
    default:
      return 'March';
  }
};

const currentFolder = folder();
const bannerMobile = require(`./images/${currentFolder}/BOB-2023-Login-320x280-Header-mobile-@1x.png`);
const bannerMobile2x = require(`./images/${currentFolder}/BOB-2024-Login-640x560-Header-mobile-@2x.png`);
const bannerTablet = require(`./images/${currentFolder}/BOB-2024-Login-Header-1024x280-tablet-@1x.png`);
const bannerTablet2x = require(`./images/${currentFolder}/BOB-2024-Login-Header-2048x560-tablet-@2x.png`);
const bannerDesktop = require(`./images/${currentFolder}/BOB-2024-Login-Header@1x.png`);
const bannerDesktop2x = require(`./images/${currentFolder}/BOB-2024-Login-Header@2x.png`);

const BannerWrapper = styled.div`
  position: relative;
  z-index: -1;
`;

const BannerContainer = styled.div`
  background-image: url(${bannerMobile});
  align-items: center;
  background-color: ${thd('primary', '#00A7AD')};
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 360px;
  justify-content: flex-start;
  z-index: -1;

  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bannerMobile2x});
  }

  .banner__title {
    //color: #fff;
    font-size: 48px;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
  }
  .banner {
    height: 280px;
  }
  ${up(
    'sm',
    css`
      background-image: url(${bannerTablet});
      background-position: center top;
      height: 280px;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bannerTablet2x});
      }

      .banner {
        height: 280px;
      }
    `
  )}
  ${up(
    'lg',
    css`
      background-image: url('${bannerDesktop}');
      background-position: center center;
      height: 350px;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url('${bannerDesktop2x}');
      }

      .banner {
        height: 350px;
      }
    `
  )}
`;

const BannerLogin = props => (
  <BannerWrapper className="banner-wrapper">
    <BannerContainer {...props}>
      <Grid>
        <Row alignItems="center" className="banner">
          <Col xs={12} md={8} lg={8} olg={2} className="banner__title__wrapper">
            <p className="banner__title">&nbsp;</p>
          </Col>
        </Row>
      </Grid>
    </BannerContainer>
  </BannerWrapper>
);

export default BannerLogin;
